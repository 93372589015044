import { atom, RecoilState } from "recoil";
import { INITIAL_AUTH_FORM_VALUES } from "../model/constants";
import { AuthTestRequest } from "../model/AuthTestRequest";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
    key: 'test-harness',
    storage: sessionStorage  // Changed from default localStorage to sessionStorage
});

export const authTestRequestAtom: RecoilState<AuthTestRequest> = atom({
    key: "authTestRequest",
    default: INITIAL_AUTH_FORM_VALUES,
    effects_UNSTABLE: [persistAtom],
});

export const keycloakStateAtom: RecoilState<Keycloak.KeycloakInstance> = atom({
    key: "keycloakState",
    default: {} as Keycloak.KeycloakInstance,
});